<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-card>
            <section-header :target="() => self.$refs.userRolesContainer"
              ><v-container>
                <v-row>
                  <v-col>User Roles</v-col>
                </v-row>
              </v-container></section-header
            >
            <v-card-text>
              <v-container fluid ref="userRolesContainer">
                <v-row>
                  <v-col cols="12" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      ref="email"
                      name="email"
                      rules="required"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        counter
                        maxlength="255"
                        hint="The email of the user"
                        label="Email Address"
                        required
                        aria-required="true"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="roles"
                      :items="rolesList"
                      chips
                      deletable-chips
                      label="Roles"
                      multiple
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="isActive"
                      rules="required"
                    >
                      <v-radio-group
                        v-model="isActive"
                        row
                        aria-required="true"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          <div>Status</div>
                        </template>
                        <fieldset>
                          <legend class="d-none" aria-hidden="true">
                            Status
                          </legend>
                          <v-radio :label="`Active`" :value="true"></v-radio>
                          <v-radio :label="`Inactive`" :value="false"></v-radio>
                        </fieldset>
                      </v-radio-group>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
          <v-card class="mt-5">
            <section-header
              :target="() => self.$refs.sponsorPermissionsContainer"
              ><v-container>
                <v-row>
                  <v-col>Sponsor Permissions</v-col>
                </v-row>
              </v-container></section-header
            >
            <v-card-text>
              <v-container fluid ref="sponsorPermissionsContainer">
                <v-row class="d-flex align-stretch">
                  <v-col cols="12" class="sponsor-list d-flex flex-column">
                    <v-checkbox
                      :label="sponsor.text"
                      class="mt-1"
                      v-for="sponsor in sponsorsList"
                      :key="sponsor.value"
                      :input-value="
                        selectedSponsors.some(
                          (item) => item.value === sponsor.value
                        )
                      "
                      v-on:change="toggleSelectedSponser(sponsor)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import SectionHeader from "@admin/components/SectionHeader.vue";

setInteractionMode("passive");

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "UsersForm",
  order: 0,
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    SectionHeader,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      originalEmail: "",
      snackbar: false,
      selectedSponsors: [],
      self: this,
    };
  },
  computed: {
    ...mapFields("users", {
      email: "user.email",
      isActive: "user.isActive",
      roles: "user.roles",
      sponsors: "user.sponsors",
    }),
    ...mapState("users", ["error", "user"]),
    ...mapState("roles", ["rolesList"]),
    ...mapState("sponsors", ["sponsorsList"]),
    isNew() {
      return this.id === "";
    },
  },
  methods: {
    ...mapActions("roles", ["getRolesList"]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("sponsors", ["getSponsorsList"]),
    ...mapActions("users", [
      "addUser",
      "clearUser",
      "clearUsers",
      "editUser",
      "getUser",
      "validateEmail",
    ]),
    toggleSelectedSponser(sponsor) {
      let sponsorIndex = this.selectedSponsors.findIndex(
        (selectedSponser) => selectedSponser.value === sponsor.value
      );
      if (sponsorIndex !== -1) {
        this.selectedSponsors.splice(sponsorIndex, 1);
      } else {
        this.selectedSponsors.push(sponsor);
      }
    },
    async submit() {
      if (this.isSubmitting || !(await this.$refs.observer.validate())) {
        return;
      }

      let success = false;
      this.isSubmitting = true;
      // the list of selected sponsors is kept separate from the data
      // model because the format of each object is different.
      // This is needed so the API can recognize and store the data
      this.sponsors = this.selectedSponsors;

      if (this.isNew) {
        success = await this.addUser();
      } else {
        success = await this.editUser(this.id);
      }
      if (success) {
        this.clearUsers(); // force re-pull from db
        await this.$router.push({ name: "UsersIndex" });
      } else {
        if (this.error === "User email must be unique.") {
          this.$refs.email.applyResult({
            errors: [this.error], // array of string errors
            valid: false, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }
        this.snackbar = !!this.error;
      }
      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await Promise.all([this.getRolesList(), this.getSponsorsList()]);
    if (this.isNew) {
      this.clearUser();
      this.setTitleObject("User");
    } else {
      await this.getUser(this.id)
        .then(() => {
          // run through the list of sponsors and assign as either selected or unselected
          this.sponsorsList.forEach((sponsorData) => {
            if (this.sponsors.indexOf(sponsorData.value) !== -1) {
              this.selectedSponsors.push(sponsorData);
            }
          });

          return true;
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
        });
      this.setTitleObject(this.email);
      this.originalEmail = this.email;
    }
    this.isLoading = false;
    this.setBreadcrumbs();
    this.setActionButtons([
      {
        component: "CancelButton",
        attributes: {
          to: "/users",
        },
        index: 0,
      },
      {
        component: "SaveButton",
        attributes: {
          onSubmit: () => this.submit(),
        },
        index: 1,
      },
    ]);
  },
  destroyed() {
    this.clearUser();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
.sponsor-list {
  height: 250px;
  max-height: 250px;
  overflow-y: auto;
}
</style>
